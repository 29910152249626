import React from "react";
import MobileDetect from "mobile-detect";

interface IMobileDetectProps {
  isPhone: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isWide: boolean;
}

export default React.createContext<IMobileDetectProps>({
  isPhone: false,
  isDesktop: false,
  isTablet: false,
  isWide: false,
});

export function getInitialPropsFromUserAgent(userAgent: string): IMobileDetectProps {
  const md = new MobileDetect(userAgent);

  const isPhone = !!md.phone();
  const isTablet = !!md.tablet();

  return {
    isPhone,
    isTablet,
    isDesktop: !isPhone && !isTablet,
    isWide: !isPhone && !isTablet,
  };
}
