import {
  ApolloLink,
  FetchResult,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
  PureQueryOptions,
  RefetchQueriesFunction,
} from "@apollo/client";
import { getQueryName } from "~/lib/apollo/getQueryName";
import notUndefinedOrNull from "~/lib/utils/notUndefinedOrNull";

const operations = new Map<string, PureQueryOptions>();
class RefetchQueriesFixLink extends ApolloLink {
  public constructor(cache: InMemoryCache) {
    super();
    setTimeout(() => {
      Array.from((cache as any).watches).map((watch: any) => {
        const queryName = getQueryName(watch.query);
        if (queryName) {
          operations.set(queryName, {
            query: watch.query,
            variables: watch.variables,
          });
        }
      });
    }, 1);
  }

  public request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {
    operations.set(operation.operationName, {
      query: operation.query,
      variables: operation.variables,
    });
    if (forward) {
      return forward(operation);
    }
    return null;
  }
}
export default RefetchQueriesFixLink;

function getNamedQuery(queryName: string): PureQueryOptions | null {
  if (operations.has(queryName)) {
    return operations.get(queryName) || null;
  }
  return null;
}

function getAllNamedQueries(queryNames: string[]): PureQueryOptions[] {
  return queryNames
    .map((queryName) => {
      return getNamedQuery(queryName);
    })
    .filter(notUndefinedOrNull);
}

export const refetchQueries: (queryNames: string[]) => RefetchQueriesFunction = (queryNames: string[]) => {
  return () => getAllNamedQueries(queryNames);
};
