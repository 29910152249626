import { NextPageContext } from "next";
import { AppProps, default as NextApp } from "next/app";
import { AppContextType } from "next/dist/shared/lib/utils";
import router from "next/router";
import { Component, createContext, useContext } from "react";
import { IntlProvider } from "react-intl";
import locales from "~/intl/locales";

type WithIntlProps = {
  intl: {
    locale: string;
    defaultLocale: string;
    messages: Record<string, string>;
  };
};

interface WithIntlContext extends AppContextType<any> {
  ctx: NextPageContext;
}

const IntlLocaleContext = createContext<{
  locale: string;
  locales: typeof locales;
  changeLocale: (locale: string) => Promise<void>;
}>({
  locale: "en-US",
  locales,
  changeLocale: async () => {},
});

export function useIntlLocale() {
  return useContext(IntlLocaleContext);
}

export default function withIntl(App: typeof NextApp) {
  return class WithIntl extends Component<AppProps & WithIntlProps> {
    static async getInitialProps(context: WithIntlContext) {
      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(context);
      }

      let localeID = "en-US";
      const availableLocales = locales.map(({ id }) => id);

      if (context.router && context.router.locale && availableLocales.includes(context.router.locale)) {
        localeID = context.router.locale;
      }

      const locale = locales.find((locale) => {
        return locale.id === localeID;
      });

      if (!locale) {
        throw new Error("Locale not found");
      }

      const intlProps = {
        locale: localeID,
        messages: await locale.messages(),
      };

      return {
        ...appProps,
        intl: intlProps,
      };
    }

    static displayName = "withIntl(App)";

    state = {
      locale: this.props.intl.locale,
      messages: this.props.intl.messages,
    };

    render() {
      const { locale, messages } = this.state;

      return (
        <IntlLocaleContext.Provider
          value={{
            locale,
            locales,
            changeLocale: async (toLocale: string) => {
              const nextLocale = locales.find((locale) => {
                return locale.id === toLocale;
              });
              if (!nextLocale) {
                return;
              }
              const messages = await nextLocale.messages();
              this.setState({ locale: toLocale, messages });
              router.replace(router.asPath, router.asPath, {
                locale: toLocale,
                scroll: false,
              });
            },
          }}
        >
          <IntlProvider locale={locale} defaultLocale={locale} messages={messages}>
            <App {...this.props} />
          </IntlProvider>
        </IntlLocaleContext.Provider>
      );
    }
  };
}
