import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import fetch from "isomorphic-unfetch";
import { getQueryName } from "~/lib/apollo/getQueryName";
import RefetchQueriesFixLink from "~/lib/apollo/RefetchQueriesFixLink";
import genWatchedMutationLink from "~/lib/genWatchedMutationLink";
import possibleTypes from "~/types/possibleTypes";

// Keep this code around. Being used for testing.
// const logOperation = new ApolloLink((operation, forward) => {
//   return forward(operation).map((data) => {
//     console.log({ operation });
//     return data;
//   });
// });

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export default function factoryApolloClient(initialState: NormalizedCacheObject | undefined, links: ApolloLink[]) {
  if (!process.browser) {
    return fromInitialState(initialState, links);
  } else if (!apolloClient) {
    apolloClient = fromInitialState(initialState, links);
    return apolloClient;
  } else {
    return apolloClient;
  }
}

function fromInitialState(initialState = {}, links: ApolloLink[]) {
  const cache = new InMemoryCache({
    possibleTypes: possibleTypes.possibleTypes,
  }).restore(initialState);

  const watchedMutation = genWatchedMutationLink(cache as any);

  setTimeout(() => {
    const previouslyTrackedWatches = Array.from((cache as any).watches)
      .map((watch: any) => {
        return {
          name: getQueryName(watch.query),
          query: watch.query,
          variables: watch.variables,
        };
      })
      .filter((trackedWatch) => {
        return trackedWatch.name;
      });

    previouslyTrackedWatches.forEach((trackedWatch) => {
      (watchedMutation as any).addRelatedQuery(trackedWatch.name, {
        query: trackedWatch.query,
        variables: trackedWatch.variables,
      });
    });
  }, 1);

  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: true,
    link: ApolloLink.from([
      ...links,
      // logOperation,
      watchedMutation,
      new RefetchQueriesFixLink(cache),
      new HttpLink({
        uri: process.env.API_URL,
        fetch,
      }),
    ]),
    cache,
  });
}
