import { Global } from "@emotion/react";
import React from "react";
import { default as ReactIntercom } from "react-intercom";
import { IUser } from "~/types/types";
import Auth from "~/components/auth/Auth";
import genIntercomUser from "~/components/intercom/genIntercomUser";

export default function Intercom() {
  const appID = ["production"].includes(process.env.GOVLAUNCH_ENV || "") ? "tb0v0145" : "ebcex29p";

  return (
    <>
      <Global
        styles={{
          "@media only screen and (max-width: 768px)": {
            '.intercom-launcher, .intercom-launcher-frame, .intercom-launcher-badge-frame, iframe[name="intercom-notifications-frame"]':
              {
                marginBottom: "60px !important",
              },
          },
        }}
      />
      <Auth>
        {({ user }) => {
          if (!user) {
            return <ReactIntercom appID={appID} />;
          }

          return <ReactIntercom appID={appID} {...genIntercomUser(user as IUser)} />;
        }}
      </Auth>
    </>
  );
}
