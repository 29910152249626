import { MutationFunctionOptions } from "@apollo/client";
import { Box, Button } from "@govlaunch/web";
import { ExecutionResult } from "graphql";
import React from "react";
import OAuth from "~/components/auth/OAuth";
import SigninLinkedInIcon from "~/components/icons/SignInLinkedInIcon";
import { ISignInMutation, ISignInMutationVariables } from "~/lib/mutations/__generated__/SignInMutation.generated";

const REDIRECT_URL = typeof window !== "undefined" ? `${window.location.origin}/oauth` : "";
const LINKEDIN_OAUTH_URL = `https://oauth.govlaunch.com/auth/linkedin?redirect=${REDIRECT_URL}`;

interface IOAuthLinkedInProps {
  signIn: (
    options?: MutationFunctionOptions<ISignInMutation, ISignInMutationVariables> | undefined,
  ) => Promise<ExecutionResult<ISignInMutation>>;
  inviteToken?: string | null;
  referralToken?: string | null;
  setBlocked: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OAuthLinkedIn({ signIn, inviteToken, referralToken, setBlocked }: IOAuthLinkedInProps) {
  return (
    <OAuth
      url={LINKEDIN_OAUTH_URL}
      onSuccess={({ token }) => {
        signIn({
          variables: {
            oauthToken: token,
            inviteToken: inviteToken || null,
            referralToken: referralToken || null,
          },
        }).catch((err: Error) => {
          if (/your account was deactivated/i.test(err.message)) {
            setBlocked(true);
          } else {
            throw err;
          }
        });
      }}
    >
      {({ loading, start, cancel }) => (
        <Button
          bg="#007AB9"
          color="white"
          width={["100%", "100%", "100%", "374px"]}
          maxWidth="374px"
          border="1px solid"
          borderColor="#ADB6C3"
          size="lg"
          onClick={() => {
            if (loading) {
              cancel();
            } else {
              start();
            }
          }}
          justifyContent="flex-start"
          leftIcon={() => (
            <Box>
              <SigninLinkedInIcon height="32px" width="32px" position="absolute" top="6px" left="12px" />
              <Box ml="40px" height="46px" borderRight="1px solid white" />
            </Box>
          )}
          pr={0}
          pl="12px"
        >
          <Box ml="16px">LinkedIn</Box>
        </Button>
      )}
    </OAuth>
  );
}
