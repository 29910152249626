import React, { createContext, useState } from "react";
import { TStripePlan } from "~/components/makers/TStripePlan";

type TSelectPlanContextState = {
  select: (plan: TStripePlan) => void;
  plan: TStripePlan | null;
};

const SelectPlanContext = createContext<TSelectPlanContextState>({
  select: () => {},
  plan: null,
});

interface ISelectPlanContextProviderProps {
  children: (state: TSelectPlanContextState) => any;
}

export function SelectPlanContextProvider({ children }: ISelectPlanContextProviderProps) {
  const [plan, setPlan] = useState<TStripePlan | null>(null);

  return (
    <SelectPlanContext.Provider
      value={{
        select: setPlan,
        plan,
      }}
    >
      {children({
        select: setPlan,
        plan,
      })}
    </SelectPlanContext.Provider>
  );
}

export const SelectPlanContextConsumer = SelectPlanContext.Consumer;
