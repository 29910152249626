import { ApolloClient, ApolloProvider, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getDataFromTree } from "@apollo/client/react/ssr";
import { AlgoliaContext, CSSReset, theme, ThemeProvider } from "@govlaunch/web";
import "draft-js/dist/Draft.css";
import { NextComponentType, NextPageContext } from "next";
import withApollo from "next-with-apollo";
import App from "next/app";
import dynamic from "next/dynamic";
import { default as Head } from "next/head";
import { parseCookies } from "nookies";
import React, { ErrorInfo } from "react";
import TagManager from "react-gtm-module";
import { StripeProvider } from "react-stripe-elements";
import { AuthProvider, logout, setAuthorization } from "~/components/auth/Auth";
import Intercom from "~/components/intercom/Intercom";
import { SelectPlanContextProvider } from "~/components/makers/SelectPlanContext";
import MobileDetectContext, { getInitialPropsFromUserAgent } from "~/components/MobileDetectContext";
import RequestCookiesContext from "~/components/RequestCookiesContext";
import AppTitleAndDescriptionSeo from "~/components/seo/AppTitleAndDescriptionSeo";
import { getImpersonateToken } from "~/lib/auth/getImpersonateToken";
import factoryApolloClient from "~/lib/factoryApolloClient";
import withServerResponse from "~/lib/route/withServerResponse";
import safeGetQueryParameter from "~/lib/utils/safeGetQueryParameter";
import withImpersonate from "~/lib/withImpersonate";
import withIntl from "~/lib/withIntl";
import "~/src/browser/BrowserUpdateNotificationStyles.css";
import GlobalSignInModal from "~/src/components/auth/GlobalSignInModal";
import SignInModalStateProvider from "~/src/components/auth/SignInModalStateProvider";
import algoliaKeys from "~/src/infra/algoliaKeys";
import sentry from "~/utils/sentry";

const { captureException } = sentry();

declare global {
  interface Window {
    Stripe: any;
    Intercom?: any;
  }
}

interface IGovlaunchAppProps {
  apollo: ApolloClient<any>;
  userAgent: string;
  cookies: {
    [key: string]: string;
  };
  impersonateToken: string | null;
  token?: string | null;
}

interface IGovlaunchInitialProps {
  Component: NextComponentType;
  ctx: NextPageContext;
}

class GovlaunchApp extends App<IGovlaunchAppProps> {
  public state = {
    stripe: null,
  };

  static async getInitialProps({ Component, ctx }: IGovlaunchInitialProps) {
    let pageProps = {};

    const userAgent = ctx.req && ctx.req.headers ? ctx.req.headers["user-agent"] : navigator.userAgent;

    if (ctx.err) {
      captureException(ctx.err, ctx);
    }

    try {
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }
    } catch (error) {
      captureException(error, ctx);
    }

    return {
      pageProps,
      userAgent,
      cookies: parseCookies(ctx),
      impersonateToken: getImpersonateToken(ctx),
      token: safeGetQueryParameter(ctx.query, "token"),
    };
  }

  componentDidMount() {
    if (window.Stripe && typeof window.Stripe === "function") {
      this.setState({
        stripe: window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY),
      });
    }

    if (process.env.GOVLAUNCH_ENV === "production") {
      TagManager.initialize({
        gtmId: "GTM-MQN3KPQ",
      });
    }
    if (process.env.GOVLAUNCH_ENV === "qa") {
      TagManager.initialize({
        gtmId: "GTM-MQN3KPQ",
        auth: "ZnPMv_K29WUP00wp3o-izg",
        preview: "env-8",
      });
    }
    if (process.env.GOVLAUNCH_ENV === "development" || process.env.NODE_ENV === "development") {
      TagManager.initialize({
        gtmId: "GTM-MQN3KPQ",
        auth: "NDBXW0VDE5BzAYsnry3CkQ",
        preview: "env-7",
      });
    }

    const { router } = this.props;

    if (router && router.query && router.query.impersonate) {
      router.replace(router.pathname, router.pathname, {
        shallow: true,
      });

      if (process.browser) {
        // eslint-disable-next-line
        // @ts-ignore
        router.prefetch("/");
      }
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error({ error, errorInfo });

    captureException(error, {
      errorInfo,
      cookies: this.props.cookies,
    });
  }

  render() {
    const { Component, pageProps, apollo, userAgent, cookies, token, impersonateToken } = this.props;

    return (
      <>
        <Head>
          <meta charSet="utf-8" />

          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="google-site-verification" content="134xCx-hY-fmlNjv38Sz_IqAcJ9Pjg44aIEUg4DGFd0" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />

          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/modern-normalize/0.5.0/modern-normalize.min.css"
          />

          <link rel="stylesheet" type="text/css" href="https://use.typekit.net/fym0rpl.css" />
          <link rel="shortcut icon" href="/static/favicon.png" />
          <link rel="stylesheet" type="text/css" href="/static/style.css" />

          <script src="https://js.stripe.com/v3/" />

          {process.env.GOVLAUNCH_ENV !== "production" && <meta name="robots" content="noindex,nofollow" />}
        </Head>
        <AppTitleAndDescriptionSeo />

        <RequestCookiesContext.Provider value={cookies}>
          <StripeProvider stripe={this.state.stripe}>
            <ApolloProvider client={apollo as any}>
              <AuthProvider cookies={cookies} token={token} impersonateToken={impersonateToken}>
                <>
                  <SignInModalStateProvider>
                    <SelectPlanContextProvider>
                      {() => (
                        <MobileDetectContext.Provider value={getInitialPropsFromUserAgent(userAgent)}>
                          <ThemeProvider theme={theme}>
                            <CSSReset />
                            <AlgoliaContext.Provider
                              value={{
                                appId: algoliaKeys.appId,
                                apiKey: algoliaKeys.apiKey,
                                environment: process.env.ALGOLIA_ENV || process.env.GOVLAUNCH_ENV || "development",
                              }}
                            >
                              <Component {...pageProps} />

                              <GlobalSignInModal />

                              <BrowserUpdate />
                            </AlgoliaContext.Provider>
                          </ThemeProvider>
                        </MobileDetectContext.Provider>
                      )}
                    </SelectPlanContextProvider>
                  </SignInModalStateProvider>

                  {process.env.NODE_ENV === "production" && <Intercom />}
                </>
              </AuthProvider>
            </ApolloProvider>
          </StripeProvider>
        </RequestCookiesContext.Provider>
      </>
    );
  }
}

export default withServerResponse(
  withApollo<NormalizedCacheObject>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ({ ctx, initialState }) => {
      return factoryApolloClient(initialState, [
        onError(({ graphQLErrors, networkError, operation, forward }) => {
          if (graphQLErrors && graphQLErrors.length > 0) {
            const topError = graphQLErrors[0];

            if (
              topError &&
              topError.extensions &&
              topError.extensions.code === "INTERNAL_SERVER_ERROR" &&
              /(invalid signature|invalid token|token)/i.test(topError.message)
            ) {
              logout(ctx);
              return forward(operation);
            }
          }

          if (networkError && networkError.message && /invalid token/i.test(networkError.message)) {
            logout(ctx);
            return forward(operation);
          }
        }),
        setAuthorization(ctx || null),
      ]);
    },
    {
      getDataFromTree: getDataFromTree,
    },
  )(withImpersonate(withIntl(GovlaunchApp) as any) as any) as any,
);

// if (process.env.GOVLAUNCH_ENV === "production") {
//   Application = withGA(process.env.GOOGLE_ANALYTICS_CODE, Router)(Application);
// }

const BrowserUpdate = dynamic(
  () => {
    return import("../src/browser/BrowserUpdateNotification");
  },
  { ssr: false },
);
