import React, { useState, ReactNode } from "react";
import SignInModalStateContext from "~/src/components/auth/SignInModalStateContext";

interface ISignInModalStateProvider {
  children: ReactNode;
}

export default function SignInModalStateProvider({ children }: ISignInModalStateProvider) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SignInModalStateContext.Provider
      value={{
        isOpen: isOpen,
        toggleOpen: () => setIsOpen(!isOpen),
        close: () => setIsOpen(false),
        open: () => setIsOpen(true),
      }}
    >
      {children}
    </SignInModalStateContext.Provider>
  );
}
