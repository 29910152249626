import { Flex, Box, Image, Text } from "@govlaunch/web";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

interface ISingInModalBackgroundProps {
  signIn: boolean;
  children: ReactNode;
}

export default function SingInModalBackground({ signIn, children }: ISingInModalBackgroundProps) {
  return (
    <Flex direction="column" alignItems="center" width={["100%", "100%", "100%", "616px"]}>
      <Box bg="gray.800" display="relative" minHeight="58px" width="100%">
        <Image
          position="absolute"
          src="/static/signin/signin-govlaunch-logo.png"
          alt="Govlaunch"
          left="4px"
          top="4px"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="586px"
        backgroundRepeat="no-repeat"
        backgroundSize="616px 586px"
        backgroundPosition="bottom center"
        backgroundImage='url("/static/signin/signin-background.png")'
      >
        <Box bg="rgba(79, 79, 79, 0.4)" mt="62px" width="180px">
          <Text
            fontSize="lg"
            fontWeight="semibold"
            lineHeight="24px"
            color="white"
            textTransform="uppercase"
            textAlign="center"
          >
            {signIn ? (
              <FormattedMessage defaultMessage="WELCOME BACK!" id="8VGC7R" />
            ) : (
              <FormattedMessage defaultMessage="JOIN FOR FREE" id="kf9pAr" />
            )}
          </Text>
        </Box>

        <Box bg="rgba(79, 79, 79, 0.4)" mt="8px" width={["360px", "360px", "480px", "480px"]} maxWidth="480px">
          <Text
            fontSize={["28px", "28px", "34px", "34px"]}
            fontWeight="semibold"
            lineHeight="42px"
            color="white"
            textTransform="uppercase"
            textAlign="center"
            as="h1"
          >
            {signIn ? (
              <FormattedMessage defaultMessage="Log In To Govlaunch" id="cKyupT" />
            ) : (
              <FormattedMessage defaultMessage="Sign Up for Govlaunch" id="cWOAGP" />
            )}
          </Text>
        </Box>
        <Box bg="white" width="100%" height="100%" mt="54px" maxWidth="618px" px="20px">
          {children}
        </Box>
      </Box>
    </Flex>
  );
}
