import React from "react";
import { Modal, ModalCloseButton, ModalOverlay, ModalContent, ModalBody } from "@govlaunch/web";
import SignIn from "~/src/components/auth/SignIn";
import { ISignInMutation } from "~/lib/mutations/__generated__/SignInMutation.generated";

interface ISigninModalProps {
  isOpen: boolean;
  inviteToken?: string | null;
  hardRefreshAfterSignin?: boolean;
  onSignIn?: (user: NonNullable<ISignInMutation["signIn"]>["user"]) => void;
  onRequestClose: (event?: any) => any;
}

export function SigninModal({
  isOpen,
  inviteToken,
  hardRefreshAfterSignin,
  onSignIn,
  onRequestClose,
}: ISigninModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onRequestClose} size={["100%", "616px", "616px", "616px"]}>
      <ModalOverlay />
      <ModalContent>
        {onRequestClose && (
          <ModalCloseButton onClick={onRequestClose} zIndex={1} top="12px" right="12px" color="white" />
        )}
        <ModalBody height="auto" p={0}>
          <SignIn inviteToken={inviteToken} hardRefreshAfterSignin={hardRefreshAfterSignin} onSignIn={onSignIn} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
