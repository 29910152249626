import http from "http";
import { NextPageContext } from "next";
import { AppProps, default as NextApp } from "next/app";
import { AppContextType } from "next/dist/shared/lib/utils";
import React, { Component } from "react";
import ServerResponseContext from "~/lib/route/ServerResponseContext";

interface IWithServerResponseContext extends AppContextType<any> {
  ctx: NextPageContext;
}

interface IWithServerResponseProps {
  response: () => http.ServerResponse;
}

export default function withServerResponse(App: typeof NextApp) {
  return class WithServerResponse extends Component<IWithServerResponseProps & AppProps> {
    static async getInitialProps(context: IWithServerResponseContext) {
      const { ctx } = context;
      let props = {};

      if (App.getInitialProps) {
        props = await App.getInitialProps(context);
      }

      return {
        ...props,
        response: () => ctx.res,
      };
    }

    static displayName = "withServerResponse(App)";

    static defaultProps = {
      response: () => null,
    };

    render() {
      const { response, ...props } = this.props;

      if (process.browser) {
        return <App {...props} />;
      }

      return (
        <ServerResponseContext.Provider value={response()}>
          <App {...props} />
        </ServerResponseContext.Provider>
      );
    }
  };
}
