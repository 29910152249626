import React from "react";
import {
  ISignInMutation,
  ISignInMutationVariables,
} from "~/lib/mutations/__generated__/SignInMutation.generated";
import OAuth from "~/components/auth/OAuth";
import { Box, Button } from "@govlaunch/web";
import { TwitterIcon } from "react-share";
import { MutationFunctionOptions } from "@apollo/client";
import { ExecutionResult } from "graphql";

const REDIRECT_URL = typeof window !== "undefined" ? `${window.location.origin}/oauth` : "";
const TWITTER_OAUTH_URL = `https://oauth.govlaunch.com/auth/twitter?redirect=${REDIRECT_URL}`;

interface IOAuthTwitterProps {
  signIn: (
    options?: MutationFunctionOptions<ISignInMutation, ISignInMutationVariables> | undefined,
  ) => Promise<ExecutionResult<ISignInMutation>>;
  inviteToken?: string | null;
  referralToken?: string | null;
  setBlocked: React.Dispatch<React.SetStateAction<boolean>>;
  mt?: string | number;
}

export default function OAuthTwitter({ signIn, inviteToken, referralToken, setBlocked, mt }: IOAuthTwitterProps) {
  return (
    <OAuth
      url={TWITTER_OAUTH_URL}
      onSuccess={({ token }) => {
        signIn({
          variables: {
            oauthToken: token,
            inviteToken: inviteToken || null,
            referralToken: referralToken || null,
          },
        }).catch((err: Error) => {
          if (/your account was deactivated/i.test(err.message)) {
            setBlocked(true);
          } else {
            throw err;
          }
        });
      }}
    >
      {({ loading, start, cancel }) => (
        <Button
          bg="#00aced"
          color="white"
          width={["100%", "100%", "100%", "374px"]}
          maxWidth="374px"
          border="1px solid"
          borderColor="#ADB6C3"
          size="lg"
          onClick={() => {
            if (loading) {
              cancel();
            } else {
              start();
            }
          }}
          justifyContent="flex-start"
          leftIcon={() => (
            <Box>
              <Box position="absolute" top="6px" left="12px">
                <TwitterIcon size={32} />
              </Box>

              <Box ml="40px" height="46px" borderRight="1px solid white" />
            </Box>
          )}
          pr={0}
          pl="12px"
          mt={mt}
        >
          <Box ml="16px">Twitter</Box>
        </Button>
      )}
    </OAuth>
  );
}
