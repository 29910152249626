import { MutationFunctionOptions, useMutation } from "@apollo/client";
import { Flex } from "@govlaunch/web";
import trimStart from "lodash/trimStart";
import { useRouter } from "next/router";
import React, { useCallback, useContext, useState } from "react";
import { AuthContext, IAuthContext } from "~/components/auth/Auth";
import { refetchQueries } from "~/lib/apollo/RefetchQueriesFixLink";
import SignInMutation from "~/lib/mutations/SignInMutation";
import { ISignInMutation, ISignInMutationVariables } from "~/lib/mutations/__generated__/SignInMutation.generated";
import SelfieQuery from "~/lib/queries/SelfieQuery";
import { useIntlLocale } from "~/lib/withIntl";
import stringOrFirst from "~/src/utils/stringOrFirst";
import globalOnSignIn from "~/src/components/auth/globalOnSignIn";
import OAuthFacebook from "~/src/components/auth/OAuthFacebook";
import OAuthLinkedIn from "~/src/components/auth/OAuthLinkedIn";
import OAuthTwitter from "~/src/components/auth/OAuthTwitter";
import SignInBlocked from "~/src/components/auth/SignInBlocked";
import SingInModalBackground from "~/src/components/auth/SignInModalBackground";
import SignInSwitch from "~/src/components/auth/SignInSwitch";

interface ISignInProps {
  inviteToken?: string | null;
  referralToken?: string | null;
  hardRefreshAfterSignin?: boolean;
  onSignIn?: (user: NonNullable<ISignInMutation["signIn"]>["user"]) => void;
}

export default function SignIn({ hardRefreshAfterSignin, onSignIn }: ISignInProps) {
  const router = useRouter();
  const [isBlocked, setIsBlocked] = useState(false);
  const [signIn, setSignIn] = useState(true);
  const { login } = useContext<IAuthContext>(AuthContext);
  const inviteToken = stringOrFirst(router.query.token || "");
  const referralToken = stringOrFirst(router.query.referral || "");
  const { locale, locales, changeLocale } = useIntlLocale();

  const [signInMutation] = useMutation<ISignInMutation, ISignInMutationVariables>(SignInMutation, {
    onCompleted: ({ signIn: data }) => {
      if (data && data.tokens && data.tokens.token) {
        login(data.tokens.token);

        if (
          data.user &&
          data.user.language &&
          locale !== data.user.language &&
          locales.map(({ id }) => id).includes(data.user.language)
        ) {
          changeLocale(data.user.language);
        }

        if (onSignIn) {
          onSignIn(data.user);
        }

        if (hardRefreshAfterSignin) {
          window.location.reload();
          return;
        }

        if (data.user && data.user.showOnboardingBanner) {
          router.push(
            router && (router.query || {}).redirectTo
              ? `/?redirectTo=/${trimStart(((router.query || {}).redirectTo || "").toString(), "/")}`
              : "/",
          );

          return;
        }

        if (data.user && data.user.__typename === "VendorUser" && data.user.company) {
          router.replace("/vendors/[vendorSlug]", `/vendors/${data.user.company.slug}`);
        }
      }
    },
    refetchQueries: refetchQueries([
      "Homepage",
      "SearchProjects",
      "ProjectsByGovernment",
      "ProjectsByIds",
      "ProjectBySlug",
      "StoryBySlug",
    ]),
    update: (proxy, { data }) => {
      if (data && data.signIn) {
        proxy.writeQuery({
          query: SelfieQuery,
          variables: {
            token: inviteToken || null,
          },
          data: {
            __typename: "Query",
            selfie: {
              ...data.signIn.user,
            },
          },
        });
      }
    },
  });

  const createOnSignInHandler = useCallback((provider: "linkedin" | "twitter" | "facebook") => {
    return async (options?: MutationFunctionOptions<ISignInMutation, ISignInMutationVariables> | undefined) => {
      const signInResult = await signInMutation(options);
      if (signInResult.data?.signIn) {
        globalOnSignIn(provider, signInResult.data?.signIn);
      }
      return signInResult;
    };
  }, []);

  return (
    <SingInModalBackground signIn={signIn}>
      {isBlocked ? (
        <SignInBlocked />
      ) : (
        <Flex height="100%" direction="column" alignItems="center" justifyContent="center">
          <OAuthLinkedIn
            signIn={createOnSignInHandler("linkedin")}
            inviteToken={inviteToken}
            referralToken={referralToken}
            setBlocked={setIsBlocked}
          />
          {false && (
            <OAuthTwitter
              signIn={createOnSignInHandler("twitter")}
              inviteToken={inviteToken}
              referralToken={referralToken}
              setBlocked={setIsBlocked}
              mt="24px"
            />
          )}
          {false && (
            <OAuthFacebook
              signIn={createOnSignInHandler("facebook")}
              inviteToken={inviteToken}
              referralToken={referralToken}
              setBlocked={setIsBlocked}
              mt="24px"
            />
          )}
          <SignInSwitch mt="34px" signIn={signIn} onSwitchClick={setSignIn} />
        </Flex>
      )}
    </SingInModalBackground>
  );
}
