export default function safeGetQueryParameter(
  query: Record<string, string | string[] | undefined>,
  parameter: string,
  defaultValue: any = "",
): string {
  if (query[parameter]) {
    if (Array.isArray(query[parameter]) && query[parameter]!.length > 0) {
      return query[parameter]![0];
    }

    if (typeof query[parameter] === "string") {
      return query[parameter] as string;
    }
  }

  return defaultValue;
}
