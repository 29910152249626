/* eslint-disable no-empty */
import base64Decode from "~/lib/utils/base64Decode";
import safeGetQueryParameter from "~/lib/utils/safeGetQueryParameter";
import { NextPageContext } from "next";

export function getImpersonateToken(ctx: NextPageContext): string | null {
  const impersonate = safeGetQueryParameter(ctx.query, "impersonate");

  if (impersonate) {
    try {
      const decoded = base64Decode(impersonate);
      const parsed = JSON.parse(decoded);

      if (parsed.token) {
        return parsed.token;
      }
    } catch {
      // Rely on it being a JWT token
      return impersonate;
    }
  }

  return null;
}
