import { gql } from "@apollo/client";
import CompanyPlanUsageFields from "~/lib/queries/fragments/CompanyPlanUsageFields";
import CompanySubscription from "~/lib/queries/fragments/CompanySubscription";
import StripeChargeFields from "~/lib/queries/fragments/StripeChargeFields";
import CompanyPlanFields from "~/lib/queries/fragments/CompanyPlanFields";

export default gql`
  mutation SignIn($oauthToken: String!, $referralToken: String, $inviteToken: String) {
    signIn(oauthToken: $oauthToken, referralToken: $referralToken, inviteToken: $inviteToken) {
      user {
        ... on GovernmentUser {
          _id
          slug
          role
          firstName
          lastName
          fullName
          avatar
          isInnovator
          jobTitle
          lastSeen
          email
          notificationEmail
          department {
            _id
            name
          }
          searchable
          socialMedia {
            linkedin
            facebook
            instagram
            youtube
            twitter
          }
          linkedinUrl
          projectsCount
          isSiteAdmin
          isLimitedSiteAdmin
          verified
          government {
            _id
            name
            slug
            logo
            projectsCount
            membersCount
            feedCount
            productsCount
            actionsCount
          }
          algoliaKeys {
            default
            projects
          }
          groups {
            _id
            slug
            name
            logo
            location
          }
          lastSignInAt
          showActionCenterBanner
          showOnboardingBanner
          canSeeActiveGovernments
          language
        }
        ... on VendorUser {
          _id
          slug
          role
          firstName
          lastName
          fullName
          avatar
          isDisruptor
          jobTitle
          lastSeen
          email
          notificationEmail
          department {
            _id
            name
          }
          searchable
          socialMedia {
            linkedin
            facebook
            instagram
            youtube
            twitter
          }
          linkedinUrl
          isSiteAdmin
          isLimitedSiteAdmin
          verified
          company {
            _id
            name
            slug
            viewerCanInviteMembers
            logo
            isDisruptor
            feedCount
            productsCount
            projectsCount
            governmentConnectionsCount
            storiesCount
            actionsCount
            groups {
              _id
            }
            planUsage {
              ...CompanyPlanUsageFields
            }
            ...CompanySubscription
            plan {
              ...CompanyPlanFields
            }
          }
          algoliaKeys {
            default
            projects
          }
          groups {
            _id
            slug
            name
            logo
            location
          }
          lastSignInAt
          showActionCenterBanner
          showOnboardingBanner
          canSeeActiveGovernments
          language
        }
        ... on PendingUser {
          _id
          slug
          role
          firstName
          lastName
          fullName
          avatar
          jobTitle
          lastSeen
          email
          notificationEmail
          department {
            _id
            name
          }
          searchable
          socialMedia {
            linkedin
            facebook
            instagram
            youtube
            twitter
          }
          linkedinUrl
          verified
          isSiteAdmin
          isLimitedSiteAdmin
          viewerCouldNotJoinReason
          algoliaKeys {
            default
            projects
          }
          groups {
            _id
            slug
            name
            logo
            location
          }
          lastSignInAt
          showOnboardingBanner
          canSeeActiveGovernments
          language
        }
      }
      tokens {
        token
      }
    }
  }

  ${CompanyPlanFields}
  ${CompanyPlanUsageFields}
  ${CompanySubscription}
  ${StripeChargeFields}
`;
