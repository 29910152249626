import { gql } from "@apollo/client";

export default gql`
  fragment CompanySubscription on Company {
    _id
    subscription {
      ... on ActiveSubscription {
        _id
        nextPaymentDate
        nextPaymentAmount
        subscribedAt
        lastCharge {
          ...StripeChargeFields
        }
        collectionMethod
        trialPeriod
        freeSubscription
      }
      ... on OverdueSubscription {
        _id
        willCancelAt
        nextPaymentAttempt
        nextPaymentAmount
        lastCharge {
          ...StripeChargeFields
        }
        trialPeriod
      }
      ... on ScheduledToCancelSubscription {
        _id
        willCancelAt
        requestedCancellationAt
        lastCharge {
          ...StripeChargeFields
        }
        trialPeriod
      }
    }
  }
`;
