import { Box } from "@govlaunch/web";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function SignInBlocked() {
  return (
    <Box mt="30px" color="red.500" width={["100%", "100%", "100%", "540px"]} fontSize="md">
      <FormattedMessage
        defaultMessage="Your account has been deactivated because we were unable to determine that you are a government user. If you feel this was an error please contact <mailto>hello@govlaunch.com</mailto>" id="YXLDZQ"
        values={{
          // eslint-disable-next-line react/display-name
          mailto: (address: string) => <a href={`mailto:${address}`}>{address}</a>,
        }}
      />
    </Box>
  );
}
