import { Text, Box, Anchor } from "@govlaunch/web";
import React from "react";
import { FormattedMessage } from "react-intl";

interface ISignInSwitchProps {
  signIn: boolean;
  onSwitchClick: React.Dispatch<React.SetStateAction<boolean>>;
  mt?: string | number;
}

export default function SignInSwitch({ signIn, mt, onSwitchClick }: ISignInSwitchProps) {
  return (
    <Box mt={mt}>
      <Text fontSize="md" fontWeight="normal" lineHeight="21px" color="#828282">
        {signIn ? (
          <FormattedMessage defaultMessage="Not a member?" id="Ruds26" />
        ) : (
          <FormattedMessage defaultMessage="Already a member?" id="F0ft6o" />
        )}{" "}
        <Anchor onClick={() => onSwitchClick(!signIn)}>
          <Text textDecoration="underline">
            {signIn ? (
              <FormattedMessage defaultMessage="Sign up for free" id="Rtxm5T" />
            ) : (
              <FormattedMessage defaultMessage="Sign In" id="Ub+AGc" />
            )}
          </Text>
        </Anchor>
      </Text>
    </Box>
  );
}
