import React from "react";
import Head from "next/head";

interface ISeoHeadProps {
  title: string;
  description: string;
}

export default function SeoHead({ title, description }: ISeoHeadProps) {
  return (
    <Head>
      <title key="pageTitle">{title}</title>

      <meta key="pageDescription" name="description" content={description} />

      <meta key="pageTwitterSite" name="twitter:site" content="@Govlaunch" />
      <meta key="pageTwitterTitle" name="twitter:title" content={title} />
      <meta key="pageTwitterDescription" name="twitter:description" content={description} />

      <meta key="pageOgTitle" property="og:title" content={title} />
      <meta key="pageOgDescription" property="og:description" content={description} />
    </Head>
  );
}
