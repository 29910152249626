import { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import jwtDecode from "jwt-decode";
import { NextPageContext } from "next";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import React, { createContext, ReactElement, useContext, useRef, useState } from "react";
import { getImpersonateToken } from "~/lib/auth/getImpersonateToken";
import SelfieQuery from "~/lib/queries/SelfieQuery";
import { Query } from "@apollo/client/react/components";
import { CookieSerializeOptions } from "cookie";
import { ISelfieQuery, ISelfieQueryVariables } from "~/lib/queries/__generated__/SelfieQuery.generated";

export interface IAuthContext {
  loading: boolean;
  user: ISelfieQuery["selfie"] | null;
  token: string | null;
  login(token: string): void;
  logout(): void;
}

export const AuthContext = createContext<IAuthContext>({
  loading: false,
  user: null,
  token: null,
  login: (): void => {},
  logout: (): void => {},
});

interface IAuthState {
  loading: boolean;
  user: ISelfieQuery["selfie"] | null;
  token: string | null;
  login(token: string): void;
  logout(): void;
}

interface ICookies {
  token?: string;
}

interface IAuthProviderProps {
  cookies: ICookies;
  token?: string | null;
  impersonateToken: string | null;
  children: ReactElement | null;
}

interface IAuthConsumerProps {
  children(state: IAuthState): ReactElement | null;
}

const COOKIE_OPTIONS: CookieSerializeOptions = {
  maxAge: 7 * 24 * 60 * 60,
  path: "/",
};

function getToken(ctx: NextPageContext | null): string | null {
  const cookies = parseCookies(ctx);

  if (cookies && cookies.token) {
    const decoded: {
      exp: number;
      nbf: number;
    } = jwtDecode(cookies.token);

    const now = Date.now().valueOf() / 1000;

    const isExpired =
      (typeof decoded.exp !== "undefined" && decoded.exp < now) ||
      (typeof decoded.nbf !== "undefined" && decoded.nbf > now);

    if (!isExpired) {
      return cookies.token;
    } else {
      // unwantedly it adds a side-effect into this function...however, it ensures that we won't have an expired token
      // hanging around
      logout();
    }
  }

  return null;
}

function login(token: string): void {
  setCookie(null, "token", token, COOKIE_OPTIONS);
}

export function loginWithContext(ctx: NextPageContext, token: string): void {
  setCookie(ctx, "token", token, COOKIE_OPTIONS);
}

export function logout(ctx?: NextPageContext): void {
  destroyCookie(ctx, "token", COOKIE_OPTIONS);
}

function getRequestHeaders(ctx: NextPageContext | null): {
  [key: string]: string;
} {
  if (ctx) {
    const impersonateToken = getImpersonateToken(ctx);

    if (impersonateToken) {
      return {
        Authorization: `Bearer ${impersonateToken}`,
      };
    }
  }

  const token = getToken(ctx);

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  return {};
}

function nextTick(): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), 1));
}

export const setAuthorization = (ctx: NextPageContext | null): ApolloLink => {
  return setContext((): Record<string, any> => {
    return nextTick().then(() => ({
      headers: {
        ...getRequestHeaders(ctx),
      },
    }));
  });
};

export const AuthProvider: React.FunctionComponent<IAuthProviderProps> = ({
  cookies = {},
  token,
  impersonateToken,
  children,
}): ReactElement | null => {
  const [hasToken, setHasToken] = useState<boolean>(Boolean(cookies.token || impersonateToken));
  const tokenRef = useRef(token || null);

  return (
    <Query<ISelfieQuery, ISelfieQueryVariables>
      variables={{
        token: tokenRef.current,
      }}
      query={SelfieQuery}
      skip={!hasToken}
    >
      {({ loading, data, updateQuery, refetch }): ReactElement => {
        return (
          <AuthContext.Provider
            value={{
              user: data ? data.selfie : null,
              loading,
              token: cookies.token || null,
              login: (token: string): void => {
                login(token);
                tokenRef.current = token;
                setHasToken(true);
                refetch();
              },
              logout: (): void => {
                logout();
                setHasToken(false);

                updateQuery(() => {
                  return {
                    __typename: "Query",
                    selfie: null,
                  };
                });
              },
            }}
          >
            {children}
          </AuthContext.Provider>
        );
      }}
    </Query>
  );
};

export default function Auth({ children }: IAuthConsumerProps): ReactElement | null {
  return <AuthContext.Consumer>{children}</AuthContext.Consumer>;
}

export function useSelfie(): ISelfieQuery["selfie"] | null {
  const { user } = useContext<IAuthContext>(AuthContext);
  return user;
}
