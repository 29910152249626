import merge from "lodash/merge";

interface IloadMessagesArgs {
  // Should be the locale filename without extension in the `./messages` directory. For example: en-US
  frontend?: string;
  // Should be the locale filename without extension in the `@govlaunch/web-intl-messages` module. For example: en-US
  web?: string;
}

function loadMessages({ frontend = "en-US", web = "en-US" }: IloadMessagesArgs) {
  return async () => {
    const frontendMessages = await import(`./messages/${frontend}.json`);
    const webMessages = await import(`@govlaunch/web-intl-messages/${web}.json`);
    return merge(parseMessages(frontendMessages.default), parseMessages(webMessages.default));
  };
}

function parseMessages(extractedMessages: Record<string, any>) {
  return Object.keys(extractedMessages).reduce<Record<string, string>>((messages, key) => {
    messages[key] = extractedMessages[key].message;
    return messages;
  }, {});
}

const LOCALES = [
  {
    id: "en-US",
    apiLanguage: "en-US",
    name: "English",
    countryCode: "US",
    messages: loadMessages({ frontend: "en-US", web: "en-US" }),
    disabled: false,
  },
  {
    id: "pt-BR",
    apiLanguage: "pt",
    name: "Portuguese (Português)",
    countryCode: "BR",
    messages: loadMessages({ frontend: "pt-BR", web: "pt-BR" }),
    disabled: false,
  },
  {
    id: "bg",
    apiLanguage: "bg",
    name: "Bulgarian (Български)",
    countryCode: "BG",
    messages: loadMessages({ frontend: "bg-BG", web: "bg-BG" }),
    disabled: false,
  },
  {
    id: "de",
    apiLanguage: "de",
    name: "German (Deutsch)",
    countryCode: "DE",
    messages: loadMessages({ frontend: "de-DE", web: "de-DE" }),
    disabled: false,
  },
  {
    id: "es",
    apiLanguage: "es",
    name: "Spanish (Español)",
    countryCode: "ES",
    messages: loadMessages({ frontend: "es-ES", web: "es-ES" }),
    disabled: false,
  },
  {
    id: "fr",
    apiLanguage: "fr",
    name: "French (Français)",
    countryCode: "FR",
    messages: loadMessages({ frontend: "fr-FR", web: "fr-FR" }),
    disabled: false,
  },
  {
    id: "it",
    apiLanguage: "it",
    name: "Italian (Italiano)",
    countryCode: "IT",
    messages: loadMessages({ frontend: "it-IT", web: "it-IT" }),
    disabled: false,
  },
  {
    id: "nl",
    apiLanguage: "nl",
    name: "Dutch (Nederland)",
    countryCode: "NL",
    messages: loadMessages({ frontend: "nl-NL", web: "nl-NL" }),
    disabled: false,
  },
  {
    id: "sv",
    apiLanguage: "se",
    name: "Swedish (Svenska)",
    countryCode: "SE",
    messages: loadMessages({ frontend: "sv-SE", web: "sv-SE" }),
    disabled: false,
  },
].filter(({ disabled }) => disabled !== true);

export default LOCALES;

export function findLanguageByLocale(locale: string | null | undefined) {
  if (!locale) {
    return null;
  }

  return LOCALES.find(({ id }) => id === locale)?.apiLanguage || "en";
}
