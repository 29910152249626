import TagManager from "react-gtm-module";
import { ISignInMutation } from "~/lib/mutations/__generated__/SignInMutation.generated";

export default function globalOnSignIn(
  provider: "linkedin" | "twitter" | "facebook",
  // eslint-disable-next-line
  _: NonNullable<ISignInMutation["signIn"]>,
) {
  TagManager.dataLayer({
    dataLayer: {
      event: "signIn",
      provider,
    },
  });

  let eventName = "signInWithLinkedIn";
  if (provider === "facebook") {
    eventName = "signInWithFacebook";
  } else if (provider === "twitter") {
    eventName = "signInWithTwitter";
  }
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
    },
  });
}
