import { gql } from "@apollo/client";

export default gql`
  fragment CompanyPlanUsageFields on PlanUsage {
    teamMembers
    products
    productsWithMultipleTags
    enrichedProducts
    groups
    groupInvitations
    projectsThisMonth
    sharingPage
  }
`;
