import { gql } from "@apollo/client";
import CompanyPlanUsageFields from "~/lib/queries/fragments/CompanyPlanUsageFields";
import CompanySubscription from "~/lib/queries/fragments/CompanySubscription";
import StripeChargeFields from "~/lib/queries/fragments/StripeChargeFields";
import CompanyPlanFields from "~/lib/queries/fragments/CompanyPlanFields";
import OnboardingFields from "~/lib/queries/fragments/OnboardingFields";

export default gql`
  query Selfie($token: String) {
    selfie(token: $token) {
      ... on GovernmentUser {
        _id
        slug
        role
        firstName
        lastName
        fullName
        avatar
        isInnovator
        jobTitle
        lastSeen
        email
        notificationEmail
        intercomUserHash
        department {
          _id
          name
        }
        searchable
        socialMedia {
          linkedin
          facebook
          instagram
          youtube
          twitter
        }
        preferences {
          showAsAuthorOnProjects
        }
        linkedinUrl
        projectsCount
        isSiteAdmin
        isLimitedSiteAdmin
        verified
        government {
          _id
          name
          slug
          logo
          projectsCount
          membersCount
          feedCount
          productsCount
          actionsCount
        }
        algoliaKeys {
          default
          projects
        }
        groups {
          _id
          slug
          name
          logo
          location
        }
        lastSignInAt
        showActionCenterBanner
        showCompleteGovernmentProfileBanner
        showOnboardingBanner
        canSeeActiveGovernments
        newsletterFrequency
        onboarding {
          ...OnboardingFields
        }
        signInAttempts {
          lookup
        }
        language
      }
      ... on VendorUser {
        _id
        slug
        role
        firstName
        lastName
        fullName
        avatar
        isDisruptor
        showDeleteUserWarning
        jobTitle
        lastSeen
        email
        notificationEmail
        intercomUserHash
        department {
          _id
          name
        }
        searchable
        preferences {
          showAsAuthorOnProjects
        }
        socialMedia {
          linkedin
          facebook
          instagram
          youtube
          twitter
        }
        linkedinUrl
        isSiteAdmin
        isLimitedSiteAdmin
        verified
        company {
          _id
          name
          slug
          viewerCanInviteMembers
          logo
          isDisruptor
          feedCount
          productsCount
          projectsCount
          governmentConnectionsCount
          storiesCount
          actionsCount
          usersCanAccessAccountPage
          groups {
            _id
          }
          planUsage {
            ...CompanyPlanUsageFields
          }
          ...CompanySubscription
          plan {
            ...CompanyPlanFields
          }
        }
        algoliaKeys {
          default
          projects
        }
        groups {
          _id
          slug
          name
          logo
          location
        }
        lastSignInAt
        showActionCenterBanner
        showOnboardingBanner
        canSeeActiveGovernments
        newsletterFrequency
        onboarding {
          ...OnboardingFields
        }
        signInAttempts {
          lookup
        }
        language
      }
      ... on PendingUser {
        _id
        slug
        role
        firstName
        lastName
        fullName
        avatar
        jobTitle
        lastSeen
        email
        notificationEmail
        intercomUserHash
        department {
          _id
          name
        }
        searchable
        preferences {
          showAsAuthorOnProjects
        }
        socialMedia {
          linkedin
          facebook
          instagram
          youtube
          twitter
        }
        linkedinUrl
        verified
        isSiteAdmin
        isLimitedSiteAdmin
        viewerCouldNotJoinReason
        algoliaKeys {
          default
          projects
        }
        groups {
          _id
          slug
          name
          logo
          location
        }
        lastSignInAt
        showOnboardingBanner
        canSeeActiveGovernments
        newsletterFrequency
        onboarding {
          ...OnboardingFields
        }
        signInAttempts {
          lookup
        }
        language
      }
    }
  }

  ${CompanyPlanUsageFields}
  ${CompanySubscription}
  ${StripeChargeFields}
  ${CompanyPlanFields}
  ${OnboardingFields}
`;
