import { OperationDefinitionNode, NameNode, DocumentNode } from "graphql";

export function getQueryName(query: DocumentNode): string | null {
  if (query.kind !== "Document") {
    return null;
  }
  if (query.definitions.length > 0) {
    const operation = query.definitions.find((definition) => {
      return definition.kind === "OperationDefinition";
    });
    if (operation && (operation as OperationDefinitionNode).name) {
      return ((operation as OperationDefinitionNode).name as NameNode).value;
    }
  }
  return null;
}
