export default function stringOrFirst(param: string | string[] | undefined): string {
  if (typeof param === "undefined") {
    return "";
  }
  
  if (Array.isArray(param)) {
    return param[0];
  }

  return param;
}
