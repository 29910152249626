import { gql } from "@apollo/client";

export default gql`
  fragment OnboardingFields on Onboarding {
    workingFor
    processStatus
    country
    state
    governmentId
    vendorName
    vendorWebsite
    linkedInUrl
    twitterUrl
    facebookUrl
    workplaceName
    workplaceWebsite
    whyYouWantToJoin
  }
`;
