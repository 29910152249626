import { IUser } from "~/types/types";

export default function genIntercomUser(user: IUser): any {
  return {
    user_id: user._id.toString(),
    // See https://app.intercom.com/a/apps/ebcex29p/settings/identity-verification/web
    // See https://app.intercom.com/a/apps/tb0v0145/settings/identity-verification/web
    user_hash: user.intercomUserHash,
    email: user.notificationEmail || user.email,
    name: `${user.firstName} ${user.lastName}`,
  };
}
