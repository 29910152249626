import { gql } from "@apollo/client";

export default gql`
  fragment CompanyPlanFields on Plan {
    _id
    name
    displayName
    tier
    isSelectable
    stripePlanId
    limits {
      products
      enrichedProducts
      productTags
      groups
      groupInvitations
      teamMembers
      projectsPerMonth
      enrichedProfile
      sharingPage
      inviteGovernments
      searchAccess
    }
  }
`;
