import { SvgBox, SvgBoxProps } from "@govlaunch/web";
import React from "react";

export default function SigninLinkedInIcon(props: SvgBoxProps) {
  return (
    <SvgBox width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <ellipse cx="15.9998" cy="16" rx="13.3333" ry="13.3333" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2801 22.9909V17.3971C24.2801 14.4001 22.6801 13.0054 20.5471 13.0054C18.8257 13.0054 18.0544 13.9526 17.6245 14.6169V13.2347H14.3816C14.4245 14.1503 14.3816 22.9909 14.3816 22.9909H17.6245V17.5423C17.6245 17.2514 17.6456 16.9601 17.7315 16.7518C17.9656 16.1691 18.4991 15.5659 19.3946 15.5659C20.5685 15.5659 21.0376 16.4605 21.0376 17.7715V22.9914H24.2801V22.9909ZM10.9652 11.9031C12.0957 11.9031 12.8002 11.1533 12.8002 10.2167C12.7792 9.26001 12.0957 8.53166 10.9867 8.53166C9.87768 8.53166 9.15219 9.25954 9.15219 10.2167C9.15219 11.1533 9.85619 11.9031 10.9447 11.9031H10.9652ZM16.2385 30.5672C8.32544 30.5672 1.91016 24.152 1.91016 16.2389C1.91016 8.32533 8.32544 1.91052 16.2385 1.91052C24.1516 1.91052 30.5669 8.32533 30.5669 16.2389C30.5669 24.152 24.1516 30.5672 16.2385 30.5672ZM12.5867 22.9909V13.2347H9.34419V22.9909H12.5867Z"
        fill="#007AB9"
      />
    </SvgBox>
  );
}
