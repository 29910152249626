import { NextPageContext } from "next";
import { AppProps, default as NextApp } from "next/app";
import { AppContextType } from "next/dist/shared/lib/utils";
import React, { Component } from "react";
import { loginWithContext } from "~/components/auth/Auth";
import { getImpersonateToken } from "~/lib/auth/getImpersonateToken";

interface IWithImpersonateContext extends AppContextType<any> {
  ctx: NextPageContext;
}

export default function withImpersonate(App: typeof NextApp) {
  return class WithImpersonate extends Component<AppProps> {
    static async getInitialProps(context: IWithImpersonateContext) {
      const { ctx } = context;

      const impersonateToken = getImpersonateToken(ctx);

      if (impersonateToken) {
        loginWithContext(ctx, impersonateToken);
      }

      if (App.getInitialProps) {
        const props = await App.getInitialProps(context);
        return props;
      }

      return {};
    }

    static displayName = "withImpersonate(App)";

    render() {
      return <App {...this.props} />;
    }
  };
}
