import * as Sentry from "@sentry/node";
import jwtDecode from "jwt-decode";
import get from "lodash/fp/get";
import noop from "lodash/fp/noop";
import { parseCookies } from "nookies";

const SentryWrapper = () => {
  if (process.env.NODE_ENV !== "production") {
    return {
      Sentry: {},
      captureException: noop,
    };
  }

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
  });

  return {
    Sentry,
    captureException: (err: any, ctx: any) => {
      Sentry.configureScope((scope) => {
        if (err.message) {
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra("statusCode", err.statusCode);
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx;
          const cookies = ctx.cookies || parseCookies(ctx);

          if (res && res.statusCode) {
            scope.setExtra("statusCode", res.statusCode);
          }

          if (cookies.token) {
            scope.setUser(getUserFromToken(cookies.token));
          }

          if (process.browser) {
            scope.setTag("ssr", "false");
            scope.setExtra("query", query);
            scope.setExtra("pathname", pathname);
          } else {
            scope.setTag("ssr", "true");
            scope.setExtra("url", req.url);
            scope.setExtra("method", req.method);
            scope.setExtra("headers", req.headers);
            scope.setExtra("params", req.params);
            scope.setExtra("query", req.query);
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach((key) => scope.setExtra(key, errorInfo[key]));
          }
        }
      });

      return Sentry.captureException(err);
    },
  };
};

function getUserFromToken(token: string | null): any {
  if (!token) {
    return null;
  }

  const userFromToken = jwtDecode(token);
  return {
    id: get("userId", userFromToken),
    username: get("user.fullName", userFromToken),
    email: get("user.email", userFromToken),
  };
}

export default SentryWrapper;
