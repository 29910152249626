/* eslint-disable no-empty */
import { Component, ReactNode } from "react";

import base64url from "base64-url";
import isObject from "lodash/fp/isObject";
import queryString from "query-string";

interface IChildProps {
  start: (args?: any) => any;
  cancel: (args?: any) => any;
  reset: (args?: any) => any;
}

interface IOAuthProps {
  url: string;
  onChange?: (args: any) => any;
  onSuccess?: (data: IOAuthResponseData) => void;
  children(state: IOAuthState & IChildProps): ReactNode;
}

interface IOAuthResponseDataProfile {
  id: string;
  emails: string[];
  firstName?: string;
  lastName?: string;
  headline?: string;
  industry?: string;
  location?: {
    country: string;
    name: string;
  };
  positions?: {
    current: boolean;
    title: string;
    company: {
      id: number;
      industry: string;
      name: string;
      size: string;
      type: string;
    };
  }[];
  publicProfileUrl?: string;
  pictureUrl?: string;
}

interface IOAuthResponseData {
  profile: IOAuthResponseDataProfile;
  token: string;
}

interface IOAuthResponse {
  provider: string;
  error?: any;
  data: IOAuthResponseData;
}

interface IOAuthState {
  loading: boolean;
  response: IOAuthResponse | null;
  error: Error | null;
}

export default class OAuth extends Component<IOAuthProps, IOAuthState> {
  static defaultProps = {
    onChange: () => {},
    onSuccess: () => {},
  };

  componentWillUnmount() {
    this.didUnmount = true;

    if (this.popup instanceof Window) {
      this.popup.close();
    }
  }

  popup: Window | null = null;
  didUnmount = false;

  safeSetState = (nextState: IOAuthState) => {
    if (!this.didUnmount) {
      const { onChange } = this.props;

      this.setState(nextState, () => {
        if (onChange) {
          onChange(this.state);
        }
      });
    }
  };

  handleStart = () => {
    const { onSuccess } = this.props;
    const { popup, promise } = start(this.props.url);
    this.popup = popup;

    this.safeSetState({
      loading: true,
      error: null,
      response: null,
    });

    promise
      .then(response => {
        this.safeSetState({
          loading: false,
          response: response,
          error: null,
        });

        if (onSuccess) {
          onSuccess(response.data);
        }
      })
      .catch(error => {
        this.safeSetState({
          loading: false,
          error,
          response: null,
        });
      });
  };

  handleCancel = () => {
    if (this.popup) {
      this.popup.close();
    }
  };

  handleReset = () =>
    this.safeSetState({
      loading: false,
      error: null,
      response: null,
    });

  render() {
    const { children } = this.props;

    return children({
      ...this.state,
      start: this.handleStart,
      cancel: this.handleCancel,
      reset: this.handleReset,
    });
  }
}

// -- Helpers ----
function start(
  url: string,
): {
  popup: Window | null;
  promise: Promise<IOAuthResponse>;
} {
  const popup = openPopup(url, "_blank");

  if (!popup) {
    return {
      popup,
      promise: Promise.reject(new Error("Failed to open popup")),
    };
  }

  return {
    popup,
    promise: new Promise((resolve, reject) => {
      listenForSuccess(popup, resolve, reject);
    }),
  };
}

let lastScheduledTimeout: NodeJS.Timeout;

function listenForSuccess(popup: Window, resolve: (response: IOAuthResponse) => void, reject: (error: Error) => void) {
  if (lastScheduledTimeout) {
    clearTimeout(lastScheduledTimeout);
  }

  let search: {
    response?: string;
  } | null = null;

  try {
    search = queryString.parse(popup.location.search);
  } catch (e) {
    search = null;
  }

  if (isObject(search) && search.response) {
    popup.close();
    clearTimeout(lastScheduledTimeout);

    const response = JSON.parse(base64url.decode(search.response));

    if (response.error) {
      const error = new Error(response.error.message);
      reject(error);
      return;
    }

    resolve(response);
    return;
  }

  if (popup.closed) {
    reject(new Error("User cancelled"));
    return;
  }

  lastScheduledTimeout = setTimeout(() => listenForSuccess(popup, resolve, reject), 100);
}

function openPopup(url: string, name: string): Window | null {
  const width = 600;
  const height = 600;
  const { top, left } = calculatePopupOffset(width, height);

  const params = [
    `width=${width}`,
    `height=${height}`,
    `top=${top}`,
    `left=${left}`,
    "scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no",
  ];

  return window.open(url, name, params.join(","));
}

function calculatePopupOffset(width: number, height: number) {
  const left = window.screenLeft ? window.screenLeft : window.screenX;
  const top = window.screenTop ? window.screenTop : window.screenY;

  return {
    left: window.innerWidth / 2 - width / 2 + left,
    top: window.innerHeight / 2 - height / 2 + top,
  };
}
