/* eslint-disable */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AboutPageSection: [
      "DisruptorProductsAboutPageSection",
      "LatestGovernmentsAboutPageSection",
      "MostPopularProject",
      "ProductsAboutPageSection",
      "ProjectsAboutPageSection",
      "ReportsAboutPageSection",
      "StoriesAboutPageSection",
      "TopFeaturedReport",
      "TopFeaturedStories",
    ],
    BlockData: [
      "CollectionsBlockData",
      "EmptySpaceBlockData",
      "EventsBlockData",
      "FeaturedStoriesBlockData",
      "GovernmentsBlockData",
      "ImageBlockData",
      "ImageLinkBlockData",
      "PodcastBlockData",
      "StatCountersBlockData",
    ],
    BookmarkTarget: [
      "Collection",
      "GovernmentPost",
      "GovernmentProject",
      "GroupPost",
      "GroupProject",
      "Product",
      "Story",
      "VendorPost",
      "VendorProject",
    ],
    CollectionContentItem: [
      "Government",
      "GovernmentProject",
      "Group",
      "GroupProject",
      "Product",
      "Story",
      "VendorProject",
    ],
    CollectionSponsoringSale: ["LumpSumCollectionSponsoringSale", "RecurringCollectionSponsoringSale"],
    ConnectTarget: [
      "Company",
      "Government",
      "GovernmentPost",
      "GovernmentProject",
      "GovernmentUser",
      "GroupPost",
      "GroupProject",
      "PendingUser",
      "Report",
      "Story",
      "VendorPost",
      "VendorProject",
      "VendorUser",
    ],
    Disruptor: ["Company", "Product", "VendorUser"],
    Entity: ["Company", "Government", "GovernmentUser", "PendingUser", "VendorUser"],
    FeaturedTarget: ["Product", "Report", "Story"],
    FlatResource: ["DownloadResource", "LinkResource", "TextResource"],
    FollowTarget: ["Government", "GovernmentUser", "PendingUser", "VendorUser"],
    GovernmentAction: ["GovernmentGroupRequest", "ProductStackRequest", "ProjectGovernmentVerificationRequest"],
    GovernmentVerificationRequest: ["ProjectGovernmentVerificationRequest"],
    GroupRequest: ["GovernmentGroupRequest", "VendorGroupRequest"],
    Innovator: ["Government", "GovernmentUser"],
    OutsideProductAddedOn: ["Government", "Product", "Story"],
    Post: ["GovernmentPost", "GroupPost", "VendorPost"],
    PostInterface: ["GovernmentPost", "GroupPost", "VendorPost"],
    ProductDeployment: [
      "AppStoreProductDeployment",
      "DesktopProductDeployment",
      "PlayStoreProductDeployment",
      "WebProductDeployment",
    ],
    Project: ["GovernmentProject", "GroupProject", "VendorProject"],
    SideContent: ["CarouselSideContent", "TextSideContent"],
    StorybookMockDataObject: [
      "Company",
      "Government",
      "GovernmentProject",
      "GovernmentUser",
      "Group",
      "GroupProject",
      "PendingUser",
      "Product",
      "Story",
      "VendorProject",
      "VendorUser",
    ],
    UpvoteTarget: [
      "Comment",
      "GovernmentPost",
      "GovernmentProject",
      "GroupPost",
      "GroupProject",
      "Product",
      "Story",
      "VendorPost",
      "VendorProject",
    ],
    User: ["GovernmentUser", "PendingUser", "VendorUser"],
    UserInterface: ["GovernmentUser", "PendingUser", "VendorUser"],
    UserProfile: ["GovernmentUser", "PendingUser", "VendorUser"],
    VendorSubscription: ["ActiveSubscription", "OverdueSubscription", "ScheduledToCancelSubscription"],
  },
};
export default result;
