import { ApolloLink, NormalizedCacheObject } from "@apollo/client";
import WatchedMutationLink from "apollo-link-watched-mutation";
import uniqBy from "lodash/fp/uniqBy";

export default function genWatchedMutationLink(cache: NormalizedCacheObject): ApolloLink {
  return new WatchedMutationLink(cache, {
    TrackLastVisitOnCollections: {
      SelfieCollections: ({ query, mutation }: any) => {
        return {
          ...query.result,
          listAllCollections: {
            ...query.result.listAllCollections,
            items: query.result.listAllCollections.items.map((item: any) => {
              if (!mutation.variables.collectionIds.includes(item._id)) {
                return item;
              }
              return {
                ...item,
                newContentCounters: {
                  governmentsCount: 0,
                  groupsCount: 0,
                  productsCount: 0,
                  projectsCount: 0,
                  storiesCount: 0,
                },
              };
            }),
          },
        };
      },
      ListAllCollections: ({ query, mutation }: any) => {
        return {
          ...query.result,
          listAllCollections: {
            ...query.result.listAllCollections,
            items: query.result.listAllCollections.items.map((item: any) => {
              if (!mutation.variables.collectionIds.includes(item._id)) {
                return item;
              }
              return {
                ...item,
                newContentCounters: {
                  governmentsCount: 0,
                  groupsCount: 0,
                  productsCount: 0,
                  projectsCount: 0,
                  storiesCount: 0,
                },
              };
            }),
          },
        };
      },
    },
    VerifyProject: {
      GovernmentActions: ({ query, mutation }: any) => {
        if (mutation.variables.governmentId !== query.result.government._id) {
          return query.result;
        }

        const data = {
          ...query.result,
          government: {
            ...query.result.government,
            actions: query.result.government.actions.map((action: any) => {
              if (action._id !== mutation.result.data.verifyProject._id) {
                return action;
              }

              return {
                ...action,
                status: "VERIFIED",
              };
            }),
          },
        };

        return data;
      },
      Selfie: ({ query }: any) => {
        return {
          ...query.result,
          selfie: {
            ...query.result.selfie,
            showActionCenterBanner: false,
          },
        };
      },
    },
    DeclineProjectVerification: {
      GovernmentActions: ({ query, mutation }: any) => {
        if (mutation.variables.governmentId !== query.result.government._id) {
          return query.result;
        }

        const data = {
          ...query.result,
          government: {
            ...query.result.government,
            actions: query.result.government.actions.map((action: any) => {
              if (action._id !== mutation.result.data.declineProjectVerification._id) {
                return action;
              }

              return {
                ...action,
                status: "DECLINED",
              };
            }),
          },
        };

        return data;
      },
      Selfie: ({ query }: any) => {
        return {
          ...query.result,
          selfie: {
            ...query.result.selfie,
            showActionCenterBanner: false,
          },
        };
      },
    },
    DismissActionCenter: {
      Selfie: ({ query }: any) => {
        return {
          ...query.result,
          selfie: {
            ...query.result.selfie,
            showActionCenterBanner: false,
          },
        };
      },
    },
    ToggleBookmark: {
      GetProfile: ({ mutation, query }: any) => {
        if (!query.result.user) {
          return query.result;
        }

        let { savedProductsCount, savedProjectsCount, savedStoriesCount, savedPostsCount } = query.result.user;
        const { viewerDidBookmark } = mutation.result.data.toggleBookmark;

        if (mutation.result.data.toggleBookmark.__typename === "Product") {
          savedProductsCount = viewerDidBookmark ? savedProductsCount + 1 : savedProductsCount - 1;
        } else if (mutation.result.data.toggleBookmark.__typename === "Project") {
          savedProjectsCount = viewerDidBookmark ? savedProjectsCount + 1 : savedProjectsCount - 1;
        } else if (mutation.result.data.toggleBookmark.__typename === "Post") {
          savedPostsCount = viewerDidBookmark ? savedPostsCount + 1 : savedPostsCount - 1;
        } else {
          savedStoriesCount = viewerDidBookmark ? savedStoriesCount + 1 : savedStoriesCount - 1;
        }

        return {
          ...query.result,
          user: {
            ...query.result.user,
            savedPostsCount,
            savedProductsCount,
            savedProjectsCount,
            savedStoriesCount,
          },
        };
      },
      UserBookmarks: ({ mutation, query }: any) => {
        if (!query.result.user) {
          return;
        }

        const isAdding = mutation.result.data.toggleBookmark.viewerDidBookmark === true;

        return {
          ...query.result,
          user: {
            ...query.result.user,
            bookmarks: isAdding
              ? [...query.result.user.bookmarks, mutation.result.data.toggleBookmark]
              : query.result.user.bookmarks.filter(({ _id }: any) => _id !== mutation.result.data.toggleBookmark._id),
          },
        };
      },
    },
    CreateComment: {
      Connect: ({ mutation, query }: any) => {
        return {
          ...query.result,
          connect: {
            ...query.result.connect,
            items: query.result.connect.items.map((item: any) => {
              if (item.target._id === mutation.variables.targetId) {
                return {
                  ...item,
                  target: {
                    ...item.target,
                    viewerDidComment: true,
                  },
                };
              }

              return item;
            }),
          },
        };
      },
      GetProfile: ({ mutation, query }: any) => {
        return {
          ...query.result,
          user: {
            ...query.result.user,
            posts: query.result.user.posts.map((post: any) => {
              if (post._id === mutation.variables.targetId) {
                return {
                  ...post,
                  viewerDidComment: true,
                };
              }

              return post;
            }),
          },
        };
      },
      PostById: ({ mutation, query }: any) => {
        return {
          ...query.result,
          postById: {
            ...query.result.postById,
            viewerDidComment:
              mutation.variables.targetId === query.result.postById._id ? true : query.result.postById.viewerDidComment,
          },
        };
      },
    },
    RemoveComment: {
      Connect: ({ mutation, query }: any) => {
        return {
          ...query.result,
          connect: {
            ...query.result.connect,
            items: query.result.connect.items.map((item: any) => {
              if (item.target._id === mutation.variables.targetId) {
                return {
                  ...item,
                  target: {
                    ...item.target,
                    viewerDidComment: false,
                  },
                };
              }

              return item;
            }),
          },
        };
      },
      GetProfile: ({ mutation, query }: any) => {
        return {
          ...query.result,
          user: {
            ...query.result.user,
            posts: query.result.user.posts.map((post: any) => {
              if (post._id === mutation.variables.targetId) {
                return {
                  ...post,
                  viewerDidComment: false,
                };
              }

              return post;
            }),
          },
        };
      },
      PostById: ({ mutation, query }: any) => {
        return {
          ...query.result,
          postById: {
            ...query.result.postById,
            viewerDidComment:
              mutation.variables.targetId === query.result.postById._id
                ? false
                : query.result.postById.viewerDidComment,
          },
        };
      },
    },
    CreateProjectForGroup: {
      Group: ({ mutation, query }: any) => {
        if (
          mutation.result.data.createProjectForGroup.group._id === query.result.groupBySlug._id &&
          mutation.result.data.createProjectForGroup.locationsPendingVerification.length === 0
        ) {
          return {
            ...query.result,
            groupBySlug: {
              ...query.result.groupBySlug,
              projectsCount: query.result.groupBySlug.projectsCount + 1,
              projects: {
                ...query.result.groupBySlug.projects,
                items: [mutation.result.data.createProjectForGroup].concat(query.result.groupBySlug.projects.items),
              },
            },
          };
        }

        return query.result;
      },

      GroupProjectsPendingVerification: ({ mutation, query }: any) => {
        if (
          mutation.result.data.createProjectForGroup.group._id === query.result.groupBySlug._id &&
          mutation.result.data.createProjectForGroup.locationsPendingVerification.length > 0
        ) {
          return {
            ...query.result,
            groupBySlug: {
              ...query.result.groupBySlug,
              projectsCount: query.result.groupBySlug.projectsCount + 1,
              projectsPendingVerification: {
                ...query.result.groupBySlug.projectsPendingVerification,
                items: [mutation.result.data.createProjectForGroup].concat(
                  query.result.groupBySlug.projectsPendingVerification.items,
                ),
              },
            },
          };
        }
      },
      Connect: ({ mutation, query }: any) => {
        if (mutation.result.data.createProjectForGroup.group._id == query.variables.targetId) {
          return {
            ...query.result,
            connect: {
              ...query.result.connect,
              items: [
                {
                  __typename: "ConnectItem",
                  _id: mutation.result.data.createProjectForGroup._id,
                  createdAt: new Date(),
                  target: mutation.result.data.createProjectForGroup,
                },
              ].concat(query.result.connect.items),
            },
          };
        }

        return query.result;
      },
    },
    CreateProjectForVendor: {
      // UserProjects: {},
      Connect: ({ mutation, query }: any) => {
        return {
          ...query.result,
          connect: {
            ...query.result.connect,
            items: [
              {
                __typename: "ConnectItem",
                _id: mutation.result.data.createProjectForVendor._id,
                createdAt: new Date(),
                target: mutation.result.data.createProjectForVendor,
              },
            ].concat(query.result.connect.items),
          },
        };
      },
      Vendor: ({ query }: any) => {
        return {
          ...query.result,
          company: {
            ...query.result.company,
            projectsCount: query.result.company.projectsCount + 1,
          },
        };
      },
      VendorProjects: ({ mutation, query }: any) => {
        if (
          mutation.result.data.createProjectForVendor.vendor._id === query.result.company._id &&
          mutation.result.data.createProjectForVendor.locationsPendingVerification.length === 0
        ) {
          return {
            ...query.result,
            company: {
              ...query.result.company,
              projects: {
                ...query.result.company.projects,
                items: [mutation.result.data.createProjectForVendor].concat(query.result.company.projects.items),
              },
            },
          };
        }
      },
      VendorPendingVerificationProjects: ({ mutation, query }: any) => {
        if (
          mutation.result.data.createProjectForVendor.vendor._id === query.result.company._id &&
          mutation.result.data.createProjectForVendor.locationsPendingVerification.length > 0
        ) {
          return {
            ...query.result,
            company: {
              ...query.result.company,
              projectsPendingVerification: {
                ...query.result.company.projectsPendingVerification,
                items: [mutation.result.data.createProjectForVendor].concat(
                  query.result.company.projectsPendingVerification.items,
                ),
              },
            },
          };
        }
      },
    },
    CreateProjectForGovernment: {
      Connect: ({ mutation, query }: any) => {
        return {
          ...query.result,
          connect: {
            ...query.result.connect,
            items: [
              {
                __typename: "ConnectItem",
                _id: mutation.result.data.createProjectForGovernment._id,
                createdAt: new Date(),
                target: mutation.result.data.createProjectForGovernment,
              },
            ].concat(query.result.connect.items),
          },
        };
      },
      SearchProjects: ({ mutation, query }: any) => {
        return {
          ...query.result,
          searchProjects: {
            ...query.result.searchProjects,
            items: [mutation.result.data.createProjectForGovernment].concat(query.result.searchProjects.items),
          },
        };
      },

      ProjectsByGovernment: ({ mutation, query }: any) => {
        return {
          ...query.result,
          projectsByGovernment: {
            ...query.result.projectsByGovernment,
            items: [mutation.result.data.createProjectForGovernment].concat(query.result.projectsByGovernment.items),
          },
        };
      },
      GetProfile: ({ query }: any) => {
        return {
          ...query.result,
          user: {
            ...query.result.user,
            projectsCount: query.result.user.projectsCount + 1,
          },
        };
      },
      UserProjects: ({ mutation, query }: any) => {
        if (!query.result.user.projects) {
          return {
            ...query.result,
            user: {
              ...query.result.user,
              projectsCount: query.result.user.projectsCount + 1,
              projects: {
                ...query.result.user.projects,
                items: [mutation.result.data.createProjectForGovernment],
                pageInfo: {
                  hasNextPage: false,
                  endCursor: null,
                },
              },
            },
          };
        }

        return {
          ...query.result,
          user: {
            ...query.result.user,
            projectsCount: query.result.user.projectsCount + 1,
            projects: {
              ...query.result.user.projects,
              items: [...query.result.user.projects.items, mutation.result.data.createProjectForGovernment],
              pageInfo: {
                __typename: "PageInfo",
                ...query.result.user.projects.pageInfo,
              },
            },
          },
        };
      },
    },

    ArchiveProject: {
      ProjectBySlug: ({ mutation, query }: any) => {
        if (query.result.projectBySlug && query.result.projectBySlug._id === mutation.variables.projectId) {
          return {
            ...query.result,
            projectBySlug: null,
          };
        } else if (query.result.projectBySlug) {
          return {
            ...query.result,
            projectBySlug: {
              ...query.result.projectBySlug,
              moreFromGovernment: query.result.projectBySlug.moreFromGovernment.filter((project: any) => {
                return project._id !== mutation.variables.projectId;
              }),
              relatedProjects: query.result.projectBySlug.relatedProjects.filter((project: any) => {
                return project._id !== mutation.variables.projectId;
              }),
            },
          };
        }
      },
      VendorProjects: ({ mutation, query }: any) => {
        return {
          ...query.result,
          company: {
            ...query.result.company,
            projects: {
              ...query.result.company.projects,
              items: query.result.company.projects.items.filter((project: any) => {
                return project._id !== mutation.variables.projectId;
              }),
            },
          },
        };
      },
      VendorPendingVerificationProjects: ({ mutation, query }: any) => {
        return {
          ...query.result,
          company: {
            ...query.result.company,
            projectsPendingVerification: {
              ...query.result.company.projectsPendingVerification,
              items: query.result.company.projectsPendingVerification.items.filter((project: any) => {
                return project._id !== mutation.variables.projectId;
              }),
            },
          },
        };
      },
      ProjectsByGovernment: ({ mutation, query }: any) => {
        return {
          ...query.result,
          projectsByGovernment: {
            ...query.result.projectsByGovernment,
            items: query.result.projectsByGovernment.items.filter((project: any) => {
              return project._id !== mutation.variables.projectId;
            }),
          },
        };
      },
      UserProjects: ({ mutation, query }: any) => {
        return {
          ...query.result,
          user: {
            ...query.result.user,
            projects: {
              ...query.result.user.projects,
              items: query.result.user.projects.items.filter((project: any) => {
                return project._id !== mutation.variables.projectId;
              }),
            },
          },
        };
      },
      ProjectsQuery: ({ mutation, query }: any) => {
        return {
          ...query.result,
          user: {
            ...query.result.user,
            projectsCount: query.result.user.projectsCount - 1,
            projects: {
              ...query.result.user.projects,
              items: query.result.user.projects.items.filter((project: any) => {
                return project._id !== mutation.variables.projectId;
              }),
            },
          },
        };
      },
      Group: ({ mutation, query }: any) => {
        return {
          ...query.result,
          groupBySlug: {
            ...query.result.groupBySlug,
            projectsCount: query.result.groupBySlug.projectsCount - 1,
            projects: {
              ...query.result.groupBySlug.projects,
              items: query.result.groupBySlug.projects.items.filter((project: any) => {
                return project._id !== mutation.variables.projectId;
              }),
            },
          },
        };
      },
    },
    RemoveGroup: {
      Vendor: ({ mutation, query }: any) => {
        return {
          ...query.result,
          company: {
            ...query.result.company,
            groups: query.result.company.groups.filter(({ _id }: any) => _id !== mutation.variables.groupId),
          },
        };
      },
    },
    LeaveGroupAsVendor: {
      Vendor: ({ mutation, query }: any) => {
        return {
          ...query.result,
          company: {
            ...query.result.company,
            groups: query.result.company.groups.filter(({ _id }: any) => _id !== mutation.variables.groupId),
          },
        };
      },
    },
    RemoveInvitee: {
      ProjectBySlug: ({ mutation, query }: any) => {
        if (
          query.result.projectBySlug &&
          mutation.variables.targetType === "PROJECT" &&
          mutation.variables.targetId == query.result.projectBySlug._id
        ) {
          return {
            ...query.result,
            projectBySlug: {
              ...query.result.projectBySlug,
              collaborators: query.result.projectBySlug.collaborators.filter(
                ({ _id }: { _id: string }) => _id !== mutation.variables.inviteeId,
              ),
            },
          };
        }

        return query.result;
      },
      GetStoryBySlug: ({ mutation, query }: any) => {
        return {
          ...query.result,
          story: {
            ...query.result.story,
            collaborators: query.result.story.collaborators.filter(
              ({ _id }: { _id: string }) => _id !== mutation.variables.inviteeId,
            ),
          },
        };
      },
      GetGovernment: ({ mutation, query }: any) => {
        return {
          ...query.result,
          government: {
            ...query.result.government,
            members: query.result.government.members.filter(
              ({ _id }: { _id: string }) => _id !== mutation.variables.inviteeId,
            ),
          },
        };
      },
    },
    PostToGroup: {
      Connect: ({ mutation, query }: any) => {
        if (!query.result.connect) {
          return query.result;
        }

        if (query.variables.targetId === mutation.variables.group) {
          const post = mutation.result.data.postToGroup;

          return {
            ...query.result,
            connect: {
              totalCount: query.result.connect.totalCount + 1,
              items: [
                {
                  __typename: "ConnectItem",
                  _id: post._id,
                  createdAt: new Date(),
                  target: {
                    __typename: "GroupPost",
                    _id: post._id,
                    body: post.body,
                    upvotesCount: post.upvotesCount,
                    commentsCount: post.commentsCount,
                    viewerDidBookmark: post.viewerDidBookmark,
                    viewerDidUpvote: post.viewerDidUpvote,
                    viewerCanEdit: post.viewerCanEdit,
                    viewerDidComment: post.viewerDidComment,
                    viewerCanDelete: post.viewerCanDelete,
                    viewerIsAuthor: post.viewerIsAuthor,
                    postGroup: post.__typename === "GroupPost" ? post.group : null,
                    postGovernment: post.__typename === "GovernmentPost" ? post.government : null,
                    created: post.createdAt,
                    postAuthor: post.author,
                    postAttachments: post.attachments,
                    postLinks: post.links,
                    postAudience: post.audience,
                    featuredAt: post.featuredAt,
                  },
                },
              ].concat(query.result.connect.items),
            },
          };
        }

        return query.result;
      },
    },
    DeletePost: {
      Vendor: ({ query }: any) => {
        if (!query.result.company) {
          return query.result;
        }

        return {
          ...query.result,
          company: {
            ...query.result.company,
            feedCount: Math.max(query.result.company.feedCount - 1, 0),
          },
        };
      },
      Connect: ({ mutation, query }: any) => {
        if (!query.result.connect) {
          return query.result;
        }

        return {
          ...query.result,
          connect: {
            ...query.result.connect,
            items: query.result.connect.items.filter((connectItem: any) => {
              return connectItem.target._id !== mutation.variables.id;
            }),
          },
        };
      },
      GetProfile: ({ mutation, query }: any) => {
        if (!query.result.user) {
          return query.result;
        }

        return {
          ...query.result,
          user: {
            ...query.result.user,
            posts: query.result.user.posts.filter((post: any) => {
              return post._id !== mutation.variables.id;
            }),
          },
        };
      },
    },
    PostToVendor: {
      Vendor: ({ query }: any) => {
        if (!query.result.company) {
          return query.result;
        }

        return {
          ...query.result,
          company: {
            ...query.result.company,
            feedCount: query.result.company.feedCount + 1,
          },
        };
      },
      Connect: ({ mutation, query }: any) => {
        if (!query.result.connect) {
          return query.result;
        }

        const post = mutation.result.data.postToVendor;

        return {
          ...query.result,
          connect: {
            ...query.result.connect,
            totalCount: query.result.connect.totalCount + 1,
            items: [
              {
                __typename: "ConnectItem",
                _id: post._id,
                createdAt: new Date(),
                target: {
                  __typename: "VendorPost",
                  _id: post._id,
                  body: post.body,
                  upvotesCount: post.upvotesCount,
                  commentsCount: post.commentsCount,
                  viewerDidBookmark: post.viewerDidBookmark,
                  viewerDidUpvote: post.viewerDidUpvote,
                  viewerCanEdit: post.viewerCanEdit,
                  viewerDidComment: post.viewerDidComment,
                  viewerCanDelete: post.viewerCanDelete,
                  viewerIsAuthor: post.viewerIsAuthor,
                  postGroup: post.__typename === "GroupPost" ? post.group : null,
                  postGovernment: post.__typename === "GovernmentPost" ? post.government : null,
                  postVendor: post.__typename === "VendorPost" ? post.vendor : null,
                  created: post.createdAt,
                  postAuthor: post.author,
                  postAttachments: post.attachments,
                  postLinks: post.links,
                  postAudience: post.audience,
                  featuredAt: post.featuredAt,
                },
              },
            ].concat(query.result.connect.items),
          },
        };
      },
    },
    PostToGovernment: {
      GetProfile: ({ mutation, query }: any) => {
        if (!query.result.user) {
          return query.result;
        }

        const post = mutation.result.data.postToGovernment;

        return {
          ...query.result,
          user: {
            ...query.result.user,
            posts: [post].concat(query.result.user.posts),
          },
        };
      },
      Connect: ({ mutation, query }: any) => {
        if (!query.result.connect) {
          return query.result;
        }

        const post = mutation.result.data.postToGovernment;

        return {
          ...query.result,
          connect: {
            ...query.result.connect,
            totalCount: query.result.connect.totalCount + 1,
            items: [
              {
                __typename: "ConnectItem",
                _id: post._id,
                createdAt: new Date(),
                target: {
                  __typename: "GovernmentPost",
                  _id: post._id,
                  body: post.body,
                  upvotesCount: post.upvotesCount,
                  commentsCount: post.commentsCount,
                  viewerDidBookmark: post.viewerDidBookmark,
                  viewerDidUpvote: post.viewerDidUpvote,
                  viewerCanEdit: post.viewerCanEdit,
                  viewerDidComment: post.viewerDidComment,
                  viewerCanDelete: post.viewerCanDelete,
                  viewerIsAuthor: post.viewerIsAuthor,
                  postGroup: post.__typename === "GroupPost" ? post.group : null,
                  postGovernment: post.__typename === "GovernmentPost" ? post.government : null,
                  created: post.createdAt,
                  postAuthor: post.author,
                  postAttachments: post.attachments,
                  postLinks: post.links,
                  postAudience: post.audience,
                  featuredAt: post.featuredAt,
                },
              },
            ].concat(query.result.connect.items),
          },
        };
      },
    },
    InviteGovernmentsToGroup: {
      Group: ({ mutation, query }: any) => {
        const currentRequests = query.result.groupBySlug.pendingRequests;

        return {
          ...query.result,
          groupBySlug: {
            ...query.result.groupBySlug,
            pendingRequests: uniqBy("_id", mutation.result.data.inviteGovernmentsToGroup.concat(currentRequests)),
          },
        };
      },
    },
    InviteVendorsToGroup: {
      Group: ({ mutation, query }: any) => {
        const currentRequests = query.result.groupBySlug.pendingRequests;

        return {
          ...query.result,
          groupBySlug: {
            ...query.result.groupBySlug,
            pendingRequests: uniqBy("_id", mutation.result.data.inviteVendorsToGroup.concat(currentRequests)),
          },
        };
      },
    },
    CancelGroupRequest: {
      Group: ({ mutation, query }: any) => {
        return {
          ...query.result,
          groupBySlug: {
            ...query.result.groupBySlug,
            pendingRequests: query.result.groupBySlug.pendingRequests.filter(
              (pendingRequest: any) => pendingRequest._id !== mutation.variables.requestId,
            ),
          },
        };
      },
    },
    LeaveGroupAsGovernment: {
      Government: ({ mutation, query }: any) => {
        return {
          ...query.result,
          government: {
            ...query.result.government,
            groups: query.result.government.groups.filter((group: any) => group._id !== mutation.variables.groupId),
          },
        };
      },
    },
    WriteComment: {
      CommentsList: ({ mutation, query }: any) => {
        if (mutation.variables.targetId !== query.variables.targetId) {
          return query.result;
        }

        return {
          ...query.result,
          comments: {
            ...query.result.comments,
            items: [...query.result.comments.items, mutation.result.data.createComment],
          },
        };
      },
    },
    ReplyComment: {
      CommentsList: ({ mutation, query }: any) => {
        return {
          ...query.result,
          comments: {
            ...query.result.comments,
            items: query.result.comments.items.map((comment: any) => {
              if (comment._id === mutation.variables.replyTo) {
                return {
                  ...comment,
                  replies: [...comment.replies, mutation.result.data.replyComment],
                };
              }

              return comment;
            }),
          },
        };
      },
    },
    DeleteComment: {
      CommentsList: ({ mutation, query }: any) => {
        return {
          ...query.result,
          comments: {
            ...query.result.comments,
            items: query.result.comments.items
              .filter((comment: any) => {
                return comment._id !== mutation.variables.commentId;
              })
              .map((comment: any) => {
                return {
                  ...comment,
                  replies: comment.replies.filter((reply: any) => {
                    return reply._id !== mutation.variables.commentId;
                  }),
                };
              }),
          },
        };
      },
    },
    LeaveGroup: {
      CollectionBySlug: ({ mutation, query }: any) => {
        return {
          ...query.result,
          collectionBySlug: {
            ...query.result.collectionBySlug,
            content: {
              ...query.result.collectionBySlug.content,
              items: query.result.collectionBySlug.content.items.map((item: any) => {
                if (item.__typename === "Group" && item._id === mutation.variables.groupId) {
                  return {
                    ...item,
                    viewerIsMember: false,
                  };
                }

                return item;
              }),
            },
          },
        };
      },
    },
  });
}
