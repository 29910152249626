import React from "react";
import { useIntl } from "react-intl";
import { useSelfie } from "~/components/auth/Auth";
import SeoHead from "~/components/seo/SeoHead";

export default function AppTitleAndDescriptionSeo() {
  const user = useSelfie();
  const intl = useIntl();

  return (
    <SeoHead
      title={intl.formatMessage({ defaultMessage: 'Search the wiki for local government innovation', id: 'PuSaJI' })}
      description={
        !user
          ? intl.formatMessage({
              defaultMessage: 'Search the wiki local government innovation for successful project examples, government technology solutions and more.', id: 'ohx8Gr',
            })
          : intl.formatMessage({
              defaultMessage: 'Find local government technology solutions, successful innovation projects and more.', id: 'IPbyEB',
            })
      }
    />
  );
}
